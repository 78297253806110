import React, { useState, useCallback } from 'react';
import Gallery from 'react-photo-gallery';
import Carousel, { Modal, ModalGateway } from "react-images";

import image0 from '../../../images/insulation/image0.jpeg';
import image1 from '../../../images/insulation/image1.jpeg';
import image2 from '../../../images/insulation/image2.jpeg';

import energyEfficiency from '../../../images/insulation/energy-efficiency.png';
import eShieldPASLogoLarge from '../../../images/insulation/eShield-PAS-Logo-Large.png';
import H236radientBarrier from '../../../images/insulation/H236radient-barrier.jpg';

const Insulation = (props) => {
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = useCallback((event, { photo, index }) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    }, []);

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const items = [{
        src: energyEfficiency,
        width: 1.75,
        height: 1,
        title: 'EnergyEfficiency'
    }, {
        src: eShieldPASLogoLarge,
        width: 1.25,
        height: 1,
        title: 'EShieldLogo'
    }, {
        src: H236radientBarrier,
        width: 1.75,
        height: 1,
        title: 'H236RadientBarrier'
    }, {
        src: image0,
        width: 1,
        height: 1,
        title: 'AtticInsulation'
    }, {
        src: image1,
        width: 1,
        height: 1,
        title: 'AtticInsulation'

    }, {
        src: image2,
        width: 1,
        height: 1,
        title: 'AtticInsulation'

    }
    ];

    return (
        <div>
            <h1>Insulation</h1>
            <br />
            <h3>Attic Insulation</h3>
            <p>
                We provide <b>eShield</b>&trade; reflective insulation and other effective alternatives to help keep your home cool in the summer and warm in the winter.<br /><br />
            </p>
            <div>
                <Gallery photos={items} onClick={openLightbox} />
                <ModalGateway>
                    {viewerIsOpen ? (
                        <Modal onClose={closeLightbox}>
                            <Carousel
                                currentIndex={currentImage}
                                views={items.map(x => ({
                                    ...x,
                                    srcset: x.srcSet,
                                    caption: x.title
                                }))}
                            />
                        </Modal>
                    ) : null}
                </ModalGateway>
            </div>
        </div>
    );
}

export default Insulation;