import React from 'react';

const SidingAndPaint = (props) => {
    return (
        <div>
            <h1>Exterior Siding and Paint</h1>
            <br />
            <h3>Siding</h3>
            <p>
                We provide <b>James Hardie</b>&reg; fiber cement siding installation as well as vinyl siding installation services.<br /><br />
                
                Fiber cement products are built to resist insect and fire and were designed to withstand high humidity, rain, hurricane winds and ultraviolet rays.
                Vinyl siding is considered to be one of the low maintenance exterior solutions that lowers energy costs by offering a layer of insulation to your property.<br /><br />

                The exterior of your home is a lot more than just curb appeal and the first thing people see.
                Besides making a building look refined and elegant, properly installed siding makes it more comfortable by locking out moisture and serves as the first line of defense from outside elements.
            </p>
            <h3>Paint</h3>
            <p>
                Painting and staining the exterior of your home is not just for aesthetic purposes, but is also a vital part of preserving the exterior of your home from premature damage due to the elements.<br /><br />

                When paint begins to chip and peel off of your exterior, or when stained surfaces become sun-beaten and flaky, the surface becomes susceptible to moisture and sun damage. 
                It is vital to utilize our professional exterior painting services to have your exterior surfaces protected from these damaging elements.<br /><br />

                We provide whole house exterior painting and staining services designed to protect and beautify your home.
            </p>
        </div>
    );
}

export default SidingAndPaint;